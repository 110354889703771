import React from "react"
import "./AiAgentDevelopmentIndustries.css"

const AiAgentDevelopmentIndustries = () => {
  const industries = [
    {
      title: "Retail and E-Commerce",
      description:
        "In the fast-paced retail world, AI agents are driving personalized shopping experiences, enhancing customer support, and optimizing inventory management. With AI agents, you can offer tailored product recommendations, assist with order management, and provide 24/7 customer support.",
      features: [
        "Virtual Shopping Assistants – Help customers find products quickly",
        "Automated Order Management – Track orders, inventory, and returns",
        "Customer Service Chatbots – Answer questions and resolve issues instantly",
        "Product Recommendations – Personalized suggestions based on browsing habits",
        "Sales Assistance Agents – Assist sales teams with customer inquiries and lead management",
        "Post-Purchase Support – Manage returns, reviews, and product satisfaction",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-retail"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Healthcare",
      description:
        "AI agents are transforming healthcare by providing real-time support for patients and medical professionals. These agents streamline administrative tasks, help in appointment scheduling, and assist in data management, while also enhancing patient interactions.",
      features: [
        "Patient Virtual Assistants – Schedule appointments and follow-up reminders",
        "Clinical Decision Support Agents – Assist in diagnosing and offering treatment suggestions",
        "Healthcare Chatbots – Provide instant medical advice and triage services",
        "Medication Management Agents – Remind patients of prescriptions and dosages",
        "Telemedicine Assistance – Facilitate online consultations and patient intake forms",
        "Administrative Support – Automate appointment booking, billing, and records management",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-healthcare"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Finance and Banking",
      description:
        "In the finance sector, AI agents are used for fraud detection, customer support, and even personalized financial advising. They help banks and financial institutions stay competitive by offering more efficient and secure services.",
      features: [
        "Virtual Financial Advisors – Provide investment tips and financial planning",
        "Fraud Detection Agents – Analyze transactions to detect anomalies and prevent fraud",
        "Banking Chatbots – Handle customer queries, account management, and transactions",
        "Loan Approval Agents – Automate loan application review and approval",
        "Transaction Monitoring Agents – Flag suspicious activity and provide real-time alerts",
        "Wealth Management Agents – Offer personalized financial strategies and portfolio suggestions",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-finance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Manufacturing",
      description:
        "Manufacturers are using AI agents to predict equipment failures, optimize supply chains, and automate production lines. These agents can analyze operational data to provide insights that drive efficiency and reduce costs.",
      features: [
        "Predictive Maintenance Agents – Monitor equipment health and schedule repairs",
        "Supply Chain Optimization Agents – Improve logistics and inventory management",
        "Quality Control Agents – Automate product inspection and ensure consistent quality",
        "Production Line Automation – Streamline assembly line processes using AI-driven agents",
        "Inventory Management Agents – Track raw materials and finished goods in real-time",
        "Demand Forecasting Agents – Use historical data to predict future demand trends",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-manufacturing"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Education",
      description:
        "AI agents in education can offer personalized learning experiences, automate administrative tasks, and even assist in grading. These agents help institutions better serve both students and staff, making education more accessible and efficient.",
      features: [
        "Intelligent Tutoring Agents – Provide personalized feedback and learning plans",
        "Administrative Support Agents – Automate grading, scheduling, and communications",
        "Student Query Chatbots – Answer academic and administrative questions instantly",
        "Virtual Class Assistants – Assist with virtual learning platforms and real-time engagement",
        "Homework Assistance Agents – Guide students through assignments and projects",
        "Exam Proctoring Agents – Monitor students during exams using AI-based detection",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-logistics"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Real Estate",
      description:
        "In real estate, AI agents are helping brokers and agents improve property searches, handle customer inquiries, and optimize marketing strategies. These agents are designed to provide quick, accurate responses and recommendations to potential buyers.",
      features: [
        "Property Recommendation Agents – Suggest properties based on client preferences",
        "Virtual Property Tours – Offer 3D walkthroughs powered by AI agents",
        "Real Estate Chatbots – Handle client inquiries and appointment scheduling",
        "Market Analysis Agents – Provide real-time insights into property value trends",
        "Lead Generation Agents – Capture and nurture real estate leads effectively",
        "Transaction Assistance Agents – Guide buyers and sellers through paperwork and processes",
      ],
      icon: (
        <i
          className="icon-ai-ml-development-services-logistics"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/01/businessman-search-new-opportunities-international-business-typing-laptop-background-world-map-hologram-scaled.jpg")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          AI Agents Across Industries: Tailored Solutions to Fit Every Need
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center mx-auto mb-8">
          AI agents are no longer a luxury—they are an essential tool for
          businesses across every sector. Whether it’s automating repetitive
          tasks, improving customer support, or optimizing operations, our AI
          Agent Development Solutions are designed to meet the specific needs of
          your industry.
        </p>
        <p className="text-lg md:text-xl text-gray-300 text-center mx-auto mb-8">
          Here are some of the AI Agent applications we build for a range of
          industries:
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tagAiAgent" key={index}>
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                {/* Dynamic height for description */}
                <div className="text-left font-normal text-sm md:text-base text-white">
                  {industry.description}
                </div>
                {industry.features && (
                  <>
                    <hr className="w-full my-4 border-t border-white border-opacity-20" />
                    <ul className="text-left text-sm md:text-base text-white mt-2 space-y-2">
                      {industry.features.map((feature, idx) => (
                        <li key={idx} className="flex items-start">
                          <span className="mr-2">•</span>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AiAgentDevelopmentIndustries
